import axios from '@axios';

const addWorkerCredits = (data) => {
  return axios.post('/workers/credits', data);
}

const getWorkerCredits = (options = {}) => {
  return axios.get('/workers/credits/balance', options);
}

const getWorkerCreditValue = (options = {}) => {
  return axios.get('/workers/credits/value', options);
}

const getWorkerCreditTransactions = (options = {}) => {
  return axios.get('/workers/credits/transactions', options);
}

export default {
  addWorkerCredits,
  getWorkerCredits,
  getWorkerCreditValue,
  getWorkerCreditTransactions
}