import axios from '@axios';

const getLocationCities = () => {
  return axios.get('/location/cities')
};

const getLocationComunes = () => {
  return axios.get('/location/comunes')
};

const getSkills = () => {
  return axios.get('/skills')
};

const getConfigAppValues = (options = {}) => {
  return axios.get('/config-app/values', options)
};

export default {
  getLocationCities,
  getLocationComunes,
  getSkills,
  getConfigAppValues,
};