import axios from "@axios";

const addQuotation = (projectId, data) => {
  return axios.post(`/projects/${projectId}/quotations`, data);
};

const getQuotations = (options = {}) => {
  return axios.get(`/quotations`, options);
};

const getQuoteById = (quoteId) => {
  return axios.get(`/quotations/${quoteId}`);
};
const getQuoteMessages = (quoteId) => {
  return axios.get(`/quotations/${quoteId}/messages`);
};

const addQuoteMessages = (quoteId, data) => {
  return axios.post(`/quotations/${quoteId}/messages`, data);
};

const setFavoriteQuoteById = (quoteId, data) => {
  return axios.put(`/quotations/${quoteId}/set-favorite`, data);
};

const setWinQuoteById = (quoteId) => {
  return axios.put(`/quotations/${quoteId}/set-win`);
};

const addWorkersSkills = (data) => {
  return axios.post(`/workers/skills`, data);
};

const getWorkersSkills = () => {
  return axios.get(`/workers/skills`);
};

const addWorkersRatings = (data) => {
  return axios.post(`/ratings`, data);
};

export default {
  addQuotation,
  getQuotations,
  getQuoteById,
  setFavoriteQuoteById,
  setWinQuoteById,
  addWorkersSkills,
  getWorkersSkills,
  addWorkersRatings,
  getQuoteMessages,
  addQuoteMessages,
};
