import axios from '@axios';

const getProjects = (options = {}) => {
  return axios.get('/projects', options)
};

const addProjects = data => {
  return axios.post('/projects', data)
};

const getProjectById = (projectId) => {
  return axios.get(`/projects/${projectId}`)
};

const updateProjectById = (projectId, data) => {
  return axios.put(`/projects/${projectId}`, data)
};

const getProjectQuotationById = ({ projectId, quotationId }) => {
  return axios.get(`/projects/${projectId}/quotations/${quotationId}`)
};

const addProjectSkills = (projectId, data) => {
  return axios.post(`/projects/${projectId}/skills`, data)
};

const deleteProjectSkillById = ({ projectId, skillId }) => {
  return axios.delete(`/projects/${projectId}/skills/${skillId}`)
};

const getLastProjects = (options = {}) => {
  return axios.get('/projects/last', options)
};

export default {
  getProjects,
  addProjects,
  updateProjectById,
  getProjectById,
  getProjectQuotationById,
  addProjectSkills,
  deleteProjectSkillById,
  getLastProjects
};