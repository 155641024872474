import auth from "./auth";
import projects from "./projects";
import quotations from "./quotations";
import credits from "./credits";
import general from "./general";
import users from "./users";

export default {
  ...auth,
  ...projects,
  ...quotations,
  ...credits,
  ...general,
  ...users,
};
