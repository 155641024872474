import axios from "@axios";

const login = (data) => {
  return axios.post("/auth/login", data);
};

const register = (data) => {
  return axios.post("/auth/register", data);
};

const profileUpdate = (data) => {
  return axios.put("/auth/update", data);
};

const forgoPassword = (data) => {
  return axios.post("/auth/forgo-password", data);
};

const changePassword = (data) => {
  return axios.post("/auth/change-password", data);
};

export default {
  login,
  register,
  profileUpdate,
  forgoPassword,
  changePassword,
};
